<template>
  <div v-if="!loading">
    <v-container
      v-if="form !== null"
      style="padding: 0 0 20px 0; margin: auto; border-radius: 5px; background:white;"
    >
      <v-form
        ref="entryForm"
        @submit.prevent="submit()"
        lazy-validation
        :disabled="loading"
      >
        <v-card
          tile
          flat
          style="
          border-bottom: 2px solid rgba(0,0,0,0.1);
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          position:sticky;
          top:60px;
          background:white;
          z-index:5;
        "
        >
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline" style="posiiton:relative;">
              <p
                :style="
                  `font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`
                "
              >
                Detail Pengajuan Izin
              </p>
            </v-toolbar-title>
          </div>
          <div class="actionButton">
            <div v-if="form.employee.id === getUserProfile.employee.id">
              <v-btn
                v-if="form.status_permission.id === 0"
                type="submit"
                rounded
                elevation="0"
                color="primary"
                class="white--text font-weight-bold ma-2"
                style="font-size:12px;"
                :loading="loading"
              >
                Submit
              </v-btn>
            </div>

            <div v-if="isAllowApprove">
              <v-dialog
                v-if="form.status_permission.id === 1"
                v-model="approveDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    rounded
                    elevation="0"
                    color="green"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Approve
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Form Persetujuan
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position:relative; top:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Catatan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea outlined v-model="form.hr_note" />
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="approveDialog = false"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="approve()"
                      color="primary"
                      type="button"
                      text
                      outlined
                      :loading="loading"
                    >
                      Approve
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-if="form.status_permission.id === 1"
                v-model="rejectDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Reject
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Form Penolakan
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position:relative; top:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Alasan penolakan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea
                            outlined
                            v-model="form.hr_reject_reason"
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="rejectDialog = false"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="reject()"
                      color="primary"
                      type="button"
                      text
                      outlined
                      :loading="loading"
                    >
                      Tolak pengajuan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div v-if="form.employee.id === getUserProfile.employee.id">
              <v-dialog
                v-if="form.status_permission.id === 0"
                v-model="cancelDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Cancel
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Form Pembatalan
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position:relative; top:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Alasan pembatalan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea v-model="form.cancel_note" outlined />
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="cancelDialog = false"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="selfCancel()"
                      color="primary"
                      type="button"
                      text
                      outlined
                      :loading="loading"
                    >
                      Batalkan pengajuan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div
              v-if="
                getUserProfile.level.find(({ id }) => id === '39') !== undefined
              "
            >
              <v-dialog
                v-if="form.status_permission.id === 2"
                v-model="cancelDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Cancel
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Form Pembatalan
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position:relative; top:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Alasan pembatalan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea v-model="form.cancel_note" outlined />
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="cancelDialog = false"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="hrCancel()"
                      color="primary"
                      type="button"
                      text
                      outlined
                      :loading="loading"
                    >
                      Batalkan pengajuan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-btn
              type="button"
              rounded
              elevation="0"
              color="red"
              class="white--text font-weight-bold ma-2"
              style="font-size:12px;"
              @click.prevent="close"
              :loading="loading"
              outlined
            >
              <v-icon small>mdi-chevron-left</v-icon>
              Kembali
            </v-btn>
          </div>
        </v-card>

        <v-card elevation="0">
          <v-toolbar-title
            class="overline"
            style="padding:0; text-align:center; height:30px;"
          >
            <p
              v-if="form.request_no !== null"
              style="font-size: 20px; margin: 0; font-weight: bold; color:indigo;position:relative;"
            >
              {{ form.request_no }}
            </p>

            <div style=" position:absolute; right:0; top:-20px;">
              <p style="font-size:10px; margin: 0;"></p>
              diajukan pada :
              {{ form.created_at }}
            </div>

            <div
              v-if="form.employee.id === getUserProfile.employee.id"
              style="position:absolute; right:10px; top:5px;"
            >
              <v-btn
                v-if="form.status_permission.id === 0"
                @click.prevent="edit()"
                type="button"
                text
                icon
                elevation="0"
                color="primary"
                class="white--text font-weight-bold"
                style="font-size:12px;"
                :loading="loading"
              >
                <v-icon small>mdi-pencil</v-icon>
                Edit
              </v-btn>
            </div>
          </v-toolbar-title>
          <v-row style="padding: 15px" no-gutters>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Nama
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-text-field dense v-model="form.employee.name" readonly />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tgl. Mulai Kerja
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    dense
                    type="date"
                    name="date"
                    step="1"
                    :value="form.join_date"
                    append-icon=""
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Status
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6.5px;">
                  <v-autocomplete
                    :items="dropdown.status"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    style="margin: 0"
                    v-model="form.status_permission.id"
                    readonly
                    append-icon=""
                  >
                    <template v-slot:selection="data">
                      <div
                        :style="
                          `color:${statusColor(
                            data.item.id
                          )}; font-weight:bolder; text-transform: uppercase;`
                        "
                      >
                        {{ data.item.name }}
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Perusahaan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:4px;">
                  <v-autocomplete
                    :items="getDropdownPlant || []"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    style="margin: 0"
                    v-model="form.company.id"
                    readonly
                    append-icon=""
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Department/ Section
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:4px;">
                  <v-autocomplete
                    :items="dropdown.department"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    style="margin: 0"
                    v-model="form.department.unit.id"
                    readonly
                    append-icon=""
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jabatan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-autocomplete
                    :items="dropdown.position"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    style="margin: 0"
                    v-model="form.level.id"
                    readonly
                    append-icon=""
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-card elevation="0" class=" mx-auto" style="padding:10px 15px;">
          <v-toolbar-title class="overline" style="padding-left:10px;">
            <p
              style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
            >
              Detail Informasi Izin
            </p>
          </v-toolbar-title>
          <v-row no-gutters>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jenis Izin
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-autocomplete
                    :items="dropdown.type"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    style="margin: 0"
                    v-model="form.permission_type"
                    readonly
                    append-icon=""
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tanggal izin
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    dense
                    type="date"
                    name="date"
                    step="1"
                    v-model="form.start_date"
                    append-icon=""
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Sampai tanggal
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    dense
                    type="date"
                    name="date"
                    step="1"
                    v-model="form.end_date"
                    append-icon=""
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="
                this.form.permission_type.id === 3 ||
                  this.form.permission_type.id === 4 ||
                  this.form.permission_type.id === 5
              "
              cols="12"
              md="6"
              style="padding: 0;"
            >
              <div style="padding: 0 10px;">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    {{
                      form.permission_type.id !== 4
                        ? 'Pada jam'
                        : 'Estimasi tiba'
                    }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center"
                  style="padding: 0;position:"
                >
                  <v-text-field
                    dense
                    type="time"
                    name="time"
                    step="1"
                    v-model="form.start_time"
                    append-icon=""
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.permission_type.id === 3"
              cols="12"
              md="6"
              style="padding: 0;"
            >
              <div style="padding: 0 10px;">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Hingga jam
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center"
                  style="padding: 0;position:"
                >
                  <v-text-field
                    dense
                    type="time"
                    name="time"
                    step="1"
                    v-model="form.end_time"
                    append-icon=""
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-bottom:20px;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jumlah hari
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    :value="form.total_permission_date"
                    dense
                    readonly
                  >
                    <template v-slot:append>
                      <div>
                        <p style="margin:0;font-size:14px;">hari</p>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.permission_type.id === 1"
              cols="12"
              md="4"
              style="padding: 0;"
            >
              <div style="padding: 0 10px;">
                <v-col cols="12" style="padding: 0;">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Nama kantor tujuan
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; display:flex; justify-content:center; align-items:center; margin-top:6.5px; margin-bottom:0;"
                >
                  <v-text-field
                    dense
                    v-model="form.destination_name"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.permission_type.id === 1"
              cols="12"
              md="8"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Alamat kantor tujuan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    v-model="form.destination_address"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Deskripsi / Alasan cuti
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    v-model="form.permission_reason"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="
                form.permission_type.id === 1 ||
                  form.permission_type.id === 2 ||
                  form.permission_type.id === 4
              "
              cols="12"
              class="d-flex justify-center align-center"
              style="padding: 0 10px;margin-bottom:20px;"
            >
              <div
                class="d-flex flex-column justify-center align-center"
                style="height:100px;border:2px dashed grey;width:200px;cursor:pointer;"
              >
                <v-icon large>mdi-image</v-icon>
                <a href="/" @click.prevent="imgDialog" style="font-size:12px;">
                  lihat bukti izin
                </a>
              </div>
              <v-dialog v-model="dialog" persistent>
                <div style="position:relative;">
                  <v-img
                    :src="
                      form.photo !== null
                        ? imgSrc
                        : 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
                    "
                    @error="imgError"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-btn
                    style="position:absolute; z-index:1; top:10px; right:10px; font-weight:bolder;"
                    fab
                    x-small
                    color="black"
                    @click="dialog = false"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
        <v-divider
          v-if="
            form.status_permission.id !== 0 && form.status_permission.id !== 1
          "
        ></v-divider>
        <v-card
          v-if="
            form.status_permission.id !== 0 && form.status_permission.id !== 1
          "
          elevation="0"
          class=" mx-auto"
          style="padding:10px 15px;"
        >
          <v-toolbar-title class="overline" style="padding-left:10px;">
            <p
              style="font-size: 16px; margin: 0; font-weight: bold; color:indigo; margin-bottom:10px;"
            >
              Catatan Konfirmasi
            </p>
          </v-toolbar-title>
          <v-row no-gutters>
            <v-col
              v-if="form.status_permission.id === -1"
              cols="12"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Alasan penolakan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="3"
                    auto-grow
                    v-model="form.hr_reject_reason"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.status_permission.id === -2"
              cols="12"
              md="4"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Dibatalkan Oleh
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field dense v-model="form.cancel_by_name" readonly />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.status_permission.id === -2"
              cols="12"
              md="8"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Alasan pembatalan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    readonly
                    :value="form.cancel_note"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="form.status_permission.id === 2"
              cols="12"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Catatan (hr)
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="3"
                    auto-grow
                    v-model="form.hr_note"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          v-if="form.approval_matrix !== null"
          mobile-breakpoint="0"
          :headers="headers"
          :items="form.approval_matrix"
          class="approvalMatrix elevation-1 mx-auto"
          :items-per-page="itemsPerPage"
          :options.sync="options"
          :server-items-length="totalSequenceData"
          hide-default-footer
        >
          <template v-slot:[`item.approver_user`]="{ item }">
            <div @click="rowClick(item)" style="cursor:pointer;">
              <v-chip
                label
                small
                style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                v-for="(item, index) in item.approver_user"
                :key="index"
              >
                {{ item.user.name }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.approved`]="{ item }">
            <div v-for="(items, index) in item.approver_user" :key="index">
              <v-chip
                v-if="items.status === 1"
                label
                small
                style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
              >
                {{ items.user.name }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.rejected`]="{ item }">
            <div v-for="(items, index) in item.approver_user" :key="index">
              <v-chip
                v-if="items.status === -1"
                label
                small
                style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
              >
                {{ items.user.name }}
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </v-container>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width:100%; height:80vh;position:relative;"
    >
      <v-btn
        type="button"
        rounded
        elevation="0"
        color="red"
        class="white--text font-weight-bold ma-2"
        style="font-size:12px; position:absolute; top: 10px; left:10px;"
        @click.prevent="close"
        :loading="loading"
        outlined
      >
        <v-icon small>mdi-chevron-left</v-icon>
        Kembali
      </v-btn>
      <p style="color: RGBA(0,0,0,0.5); font-size:24px; font-weight:bold;">
        DATA NOT FOUND
      </p>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center" style="height:100%;">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'permitsubmission',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      dialog: false,
      approveDialog: false,
      rejectDialog: false,
      cancelDialog: false,
      loading: false,
      isEdit: false,
      form: null,
      sequence: 0,
      wWidth: window.innerWidth,
      rules: {
        typeRules: [],
        joinDateRules: [],
        descriptionRules: [],
        dayOffRules: []
      },
      dropdown: {
        department: [],
        position: [],
        status: [],
        type: []
      },

      itemsPerPage: 10,
      headers: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],
      totalSequenceData: 1,
      options: {},

      matrix: [],
      isAllowApprove: false,
      imgSrc: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  async mounted() {
    this.loading = true
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initDropdown()
    await this.detail()
    this.authorityCheck()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions([
      'dropdownPlant',
      'dropdownDepartment',
      'loadPermissionApprovalNeeds'
    ]),
    ...mapMutations(['setPermissionDetail']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    imgDialog() {
      this.imgSrc = this.form.photo
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    imgError() {
      // this.isImgError = true
      this.imgSrc =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    async detail() {
      this.loading = true
      let url = ''
      if (this.$route.query.redirect_from !== undefined) {
        url = `${this.hrsApi}employee/permission/detail/${this.$route.params.id}?redirect_from=inappnotification`
      } else {
        url = `${this.hrsApi}employee/permission/detail/${this.$route.params.id}`
      }
      await axios
        .get(url)
        .then(res => {
          console.log(res.data.data)
          if (res.data.status_code !== '-99') {
            this.form = res.data.data
            if (res.data.data.permission_type === null) {
              this.form.permission_type = '-'
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    initDropdown() {
      this.dropdownPlant()
      this.dropdownDepartment()
      this.dropdownPosition()
      this.dropdownStatus()
      this.dropdownType()
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    close() {
      if (this.form !== null) {
        this.$refs.entryForm.reset()
      }
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/hr/permission/submission') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/hr/permission')
        }
      } else {
        this.$router.push('/hr/permission')
      }
    },

    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },

    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },
    async dropdownType() {
      await axios
        .get(`${this.hrsApi}master/universal/permissiontype/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.type = res.data.data)
          }
          return (this.dropdown.type = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.type = [])
        })
    },
    edit() {
      this.setPermissionDetail(this.form)
      setTimeout(() => {
        this.$router.push('/hr/permission/submission')
      }, 300)
    },
    dropdownStatus() {
      this.dropdown.status = [
        {
          id: -2,
          name: 'Canceled'
        },
        {
          id: -1,
          name: 'Rejected'
        },
        {
          id: 0,
          name: 'draft'
        },
        {
          id: 1,
          name: 'Waiting Approval'
        },
        {
          id: 2,
          name: 'Approved'
        }
      ]
    },
    async submit() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/submit`, {
          // act: 'submit',
          id: this.$route.params.id
        })
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async approve() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/approve_hr`, {
          id: this.$route.params.id,
          note: this.form.hr_note
        })
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.loadPermissionApprovalNeeds()
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async reject() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/reject_leader`, {
          id: this.$route.params.id,
          note: this.form.hr_reject_reason
        })
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.loadPermissionApprovalNeeds()
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async cancel() {
      this.cancelDialog = false
    },
    async hrCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/cancel_by_hr`, {
          id: this.$route.params.id,
          note: this.form.cancel_note
        })
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    // SELF CANCEL BLM BISA
    async selfCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/cancel_before_submit`, {
          id: this.$route.params.id,
          note: this.form.cancel_note
        })
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async authorityCheck() {
      if (this.form !== null) {
        if (this.form.status_permission.id === 1) {
          // if (
          //   this.getUserProfile.level.find(({ id }) => id === '38') !== undefined
          // ) {
          if (this.form.approval_matrix !== null) {
            await axios
              .get(
                `${this.oAuthApi}approval_matrix/document/allow_user?document_id=${this.form.approval_matrix[0].document_id}&application_id=21`
              )
              .then(res => {
                console.log(res)
                if (res.data.status_code === '00') {
                  // if (res.data.is_allow_approve !== 0) {
                  this.isAllowApprove = true
                  return null
                  // }
                }
                this.isAllowApprove = false
                return null
              })
              .catch(err => {
                this.isAllowApprove = false
                console.log(err)
                return null
              })
          }
        }
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        default:
          break
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }
}
</style>
